import type { LinkProps } from "@remix-run/react";
import { Link } from "@remix-run/react";
import { type ButtonHTMLAttributes } from "react";
import { twMerge } from "tailwind-merge";
import { Spinner } from "./Spinner";

type Variant = "solid" | "outline" | "in-card" | "outline-red";

const getVariantClasses = (variant: Variant) => {
  switch (variant) {
    case "solid":
      return `border-transparent bg-green-600 text-white hover:bg-green-600 focus:ring-green-600 focus:ring-offset-2
            dark:border-transparent dark:bg-green-600 dark:text-white dark:hover:bg-green-600 dark:focus:ring-green-600 dark:focus:ring-offset-2`;
    case "outline":
      return `border-[1px] border-blue-500 text-blue-500 hover:text-white hover:bg-blue-500 hover:border-blue-500 focus:ring-blue-500 focus:ring-offset-2
            dark:border-blue-500 dark:text-blue-500 dark:hover:text-white dark:hover:bg-blue-500 dark:hover:border-blue-500 dark:focus:ring-blue-500 dark:focus:ring-offset-2`;
    case "outline-red":
      return `border-[1px] border-red-500 text-red-500 hover:text-white hover:bg-red-500 hover:border-red-500 focus:ring-red-500 focus:ring-offset-2
            dark:border-red-500 dark:text-red-500 dark:hover:text-white dark:hover:bg-red-500 dark:hover:border-red-500 dark:focus:ring-red-500 dark:focus:ring-offset-2`;
    case "in-card":
      return `bg-background py-[0.5rem] px-2 text-slate-900 hover:text-white hover:bg-slate-900 hover:border-slate-900 focus:ring-slate-900
      dark:bg-slate-900 dark:text-slate-200 dark:hover:text-white dark:hover:bg-slate-800 dark:hover:border-slate-800 dark:focus:ring-slate-800        
      `;
  }
};

type ButtonProps = { variant?: Variant; loading?: boolean } & (
  | LinkProps
  | ButtonHTMLAttributes<HTMLButtonElement>
);

export const Button = ({
  className,
  children,
  loading,
  variant = "solid",
  ...props
}: ButtonProps) => {
  const commonClasses = `${
    loading ? "cursor-wait" : ""
  } py-[1rem] px-4 inline-flex justify-center items-center gap-2 rounded-md transition-all text-base focus:outline-none focus:ring-2`;
  const variantClasses = getVariantClasses(variant || "solid");
  const whenDisabledButton = `disabled:opacity-50 disabled:cursor-not-allowed`;
  const linkIsDisabled = "disabled" in props && props.disabled;
  const whenDisabledLink = linkIsDisabled
    ? `opacity-50 cursor-not-allowed`
    : ``;

  return "to" in props ? (
    <Link
      role="button"
      type="button"
      className={twMerge(
        commonClasses,
        variantClasses,
        whenDisabledLink,
        className
      )}
      {...props}
      onClick={(e) => {
        // do nothing if link is disabled
        if (linkIsDisabled) {
          e.preventDefault();
          return;
        }
      }}
    >
      {children}
    </Link>
  ) : (
    <button
      className={twMerge(
        commonClasses,
        variantClasses,
        whenDisabledButton,
        className
      )}
      {...props}
    >
      <div className={`${loading ? "opacity-0" : ""}`}>{children}</div>
      {loading && (
        <div className="absolute m-auto align-bottom">
          <Spinner className={variant === "solid" ? "text-white" : ""} />
        </div>
      )}
    </button>
  );
};
